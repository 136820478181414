.App {
  /* image (repeat) as background (https://d1gtzhkuu831vg.cloudfront.net/4-afd20868c555ba8aed72b7358239d301.jpg)
     Repeat vertically and horizontally with overflow hidden */
  background: url(https://d1gtzhkuu831vg.cloudfront.net/4-afd20868c555ba8aed72b7358239d301.jpg) repeat;
  overflow: hidden;
  /* Fill the entire viewport */
  height: 100vh;
  width: 100vw;
  /* Center the survey widget */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Steck items above each other */
  flex-direction: column;
}

.Pane {
  /* Pane is a container for the survey widget with blurred part transparent background (tinted black) */
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  /* Surround the widget ; minimum margin of 20px */
  /* padding: 20px; */
  margin: 20px;
  min-width: 90%;
  max-width: 80%;
  max-height: 90%;
  min-height: 50%;
  /* rounded corners */
  border-radius: 20px;
  /* Shadow */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  /* Keep content within the pane */
  display: flex;
  /* Scrolling */
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  /* IF the content is smaller than the pane, center it */
}

::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe
{
    display: none;
}