.sv-question__title {
    background-color: rgba(
        24, 127, 44, 0.2
    );
    border-radius: 10px;

    text-align: center;
}

.sv-title {
    margin-bottom: .6em;
}

.sv-page {
    margin-top: 1em;
}

.sv-page__title {
    /* Make smaller (1.5 em) */
    font-size: 1.5em;

    /* Make centered */
    text-align: center;
}

.sv-container-modern__title {
    display: none;
}

.sv-container-modern {
    margin-bottom: 10px;
}

.sv-table tr:first-child .sv-table__cell {
    padding-top: 10px;
}

td {
    padding: 10px;
}

.sv-selectbase {
    height: 100%;
}

.sv-body__page {
    height: 100%;
}

.navigation-progress {
    /* place at top of page */
    background-color: rgba(
        24, 127, 44, 0.5
    );
    height: 100%;
}

.navigation-progress-bar {
    width: 100%;
    top: 0;
    background-color: rgba(162, 163, 165, 0.5);
    /* Adjust height to child element */
    height: 100%;
}

.navigation {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: transparent;
    height: 5px;
}

.survey-complete {
    width: 100%;
    margin: 20px;

    display:flow-root;
}

#container {
    display: grid;

    width: 100%;

    margin-top: 10vh;
    margin-bottom: 10vh;

    grid-template-columns: 1fr;
}

.survey-complete-layers {
    grid-column: 1;
    grid-row: 1;

    width: 70%;
    justify-self: center;

    /* Align child elements beside each other horizontally */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.survey-complete-label-container {
    /* Align child elements beside each other horizontally */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
}

.survey-complete-label {
    font-size: 1.5em;
    font-weight: bold;
    width: 50%;

    margin: 5px;
    margin-right: 10px;
    margin-left: 10px;
}

.survey-complete-label-right {
    text-align: right;
}

.survey-complete-bar-container {
    /* Align child elements beside each other horizontally */
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
}

.survey-complete-bar {
    height: 100%;
}

.survey-complete-graph-bar-left {
    border-top-left-radius: 1.5em;
    border-bottom-left-radius: 1.5em;
}

.survey-complete-graph-bar-right {
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
}

.survey-complete-title {
    font-family: "Raleway", sans-serif;
    font-size: 1.1em;
    font-weight: 700;
    margin: 10px;
    margin-right: 30px;
    margin-left: 30px;
    color: var(--darkreader-text--text-color);
}

.sv-radio--checked .sv-radio__svg {
    border-color: rgba(17, 87, 30, 0.582);
    fill: rgba(17, 87, 30, 0.582);
}

.survey-complete-text {
    font-size: 1em;
    font-weight: 550;
    margin: 5px;
    margin-right: 10px;
    margin-left: 10px;
    margin-left: 30px;
    text-align: center;
}